<div class="footer">
  <div class="footer-link">
    <div class="container-xxl">
      <div class="row">
        <div class="col-md-3">
          <a href="/"><img src="assets/img/footer/logo-footer.png" alt="logo footer" width="170" height="40"></a>
          <div class="social">
            <a href="#" target="_blank" rel="noopener"><img
                src="assets/img/footer/fb-logo.svg" alt="fb" width="24" height="24"></a>
            <a href="#" target="_blank" rel="noopener"><img
                src="assets/img/footer/twitter.svg" alt="twitter" class="mx-2" width="24" height="24"></a>
            <a href="#" rel="noopener"><img
                src="assets/img/footer/insta-logo.svg" alt="insta" class="me-2" width="24" height="24"></a>
            <a href="https://www.linkedin.com/company/amber-iot/" target="_blank" rel="noopener"><img
                src="assets/img/footer/linkedin-logo.svg" alt="linkedin" class="me-2" width="24" height="24"></a>
            <a href="#" rel="noopener"><img
                src="assets/img/footer/youtube.svg" alt="youtube" width="34" height="24"></a>
          </div>
        </div>
        <div class="col-md-2">
          <h5>Quick Links</h5>
          <p><a [routerLink]="'/about-us'" routerLinkActive="active">About Us</a></p>
          <p><a [routerLink]="'/IOT-solutions'" routerLinkActive="active">IOT Solutions</a></p>
          <p><a [routerLink]="'/industries'" routerLinkActive="active">Industries</a></p>
          <p><a [routerLink]="'/contact-us'" routerLinkActive="active">Contact Us</a></p>

        </div>
        <div class="col-md-4">
          <h5>IOT Solutions</h5>
          <div class="row">
            <div class="col-md-6">
          <p><a [routerLink]="'/device-detail/amber-pulse'" routerLinkActive="active">Amber Pulse</a></p>
          <p><a [routerLink]="'/device-detail/amber-protect'" routerLinkActive="active">Amber Protect</a></p>
          <p><a [routerLink]="'/device-detail/amber-guard'" routerLinkActive="active">Amber Guard</a></p>
          <p><a [routerLink]="'/device-detail/amber-meerKat'" routerLinkActive="active">Amber MeerKat</a></p>

            </div>
            <div class="col-md-6">
              <p><a [routerLink]="'/device-detail/amber-alarm'" routerLinkActive="active">Amber Alarm</a></p>
              <p><a [routerLink]="'/device-detail/amber-drylink'" routerLinkActive="active">Amber DryLink</a></p>
              <p><a [routerLink]="'/device-detail/amber-temp'" routerLinkActive="active">Amber Temp</a></p>
              <p><a [routerLink]="'/device-detail/amber-level'" routerLinkActive="active">Amber Level</a></p>

            </div>
          </div>


        </div>
        <div class="col-md-3">
          <h5>Contact Us</h5>
          <div class="col address">
            <p><img src="assets/img/footer/location.svg" alt="location" class="location" width="14" height="18">
              210 Amarand Ave, 3rd Floor,<br>
              Pegasus Building 1, Menlyn Maine,<br>
              Waterkloof Glen (ext 2)<br>
              Pretoria 0181<br>
              Gauteng,<br>
              South Africa.</p>
            <p><img src="assets/img/footer/phone.svg" alt="phone" class="phone" width="14" height="19"> +27 12 483-8600</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-xxl">
      <div class="row">
        <div class="col-md-12">
          <div class="group-of-companies">
            <div class="row">
              <div class="col-8">
                <div class="logo-1">
                  <a href="https://myambergroup.com/" target="_blank" rel="noopener"> <a
                      href="https://myambergroup.com/" target="_blank" rel="noopener"> <img
                        src="assets/img/footer/ambergroup-footer-logo.png" alt="ambergroup" width="179" height="34"></a></a>
                </div>
              </div>
              <div class="col-4">
                <p class="text-end">Amber Group Subsidiary Companies</p>
              </div>
            </div>
            <hr>
            <div class="d-flex flex-sm-row flex-column justify-content-between">
              <div class="col"> <a href="https://amberconnect.com/" target="_blank" class="logo-2" rel="noopener"><img
                    src="assets/img/footer/ac-logo.png" alt="amberconnect" width="151" height="26"></a></div>

                    <div class="col"> <a href="https://amberconnect.com/" target="_blank" class="logo-2" rel="noopener"><img
                      src="assets/img/footer/amber-innovations.png" alt="amber-innovations" width="212" height="29"></a></div>

              <div class="col"> <a href="https://www.amberfuels.com/" target="_blank" class="logo-2" rel="noopener">
                  <img src="assets/img/footer/amber-fuel.png" alt="amber-fuel" width="119" height="26"> </a></div>
              <div class="col"> <a href="https://myamberpay.com/" target="_blank" class="logo-2" rel="noopener"> <img
                    src="assets/img/footer/amber-pay.png" alt="amber-pay" width="119" height="26"></a></div>
     
            </div>
            <div class="d-flex flex-sm-row flex-column justify-content-between">
              
              <div class="col"> <a href="https://myambergroup.com/our-business.html#ar-tab" target="_blank"
                class="logo-2" rel="noopener"> <img src="assets/img/footer/amber-rewards.png"
                  alt="amber-rewards" width="149" height="26"></a>
            </div>

              <div class="col"><a href="https://myambergroup.com/our-business.html#av-tab" target="_blank"
                  class="logo-2" rel="noopener"> <img src="assets/img/footer/amber-aviation.png" alt="amber-aviation" width="144" height="26">
                </a>
              </div>
              <div class="col"><a href="https://myambergroup.com/our-business.html#aha-tab" target="_blank"
                  class="logo-2" rel="noopener"> <img src="assets/img/footer/amber-academy.png" alt="amber-academy" width="177" height="23">
                </a>
              </div>
              <div class="col"> <a href="https://www.ambercybereye.com/" target="_blank" class="logo-2"
                  rel="noopener"><img src="assets/img/footer/amber-cybereye.png" alt="amber-cybereye" width="166" height="23"></a></div>
              <!-- <div class="col"> <a href="https://myambergroup.com/our-business.html#ais-tab" target="_blank"
                  class="logo-2" rel="noopener"> <img src="assets/img/footer/amber-iot.png" alt="amber-iot" width="116" height="26"> </a>
              </div> -->
            </div>
            <div class="d-flex flex-sm-row flex-column justify-content-between">
              <div class="col"> <a href="https://amberfundme.com/" target="_blank" class="logo-2" rel="noopener"> <img
                    src="assets/img/footer/amber-foundation.png" alt="amber-foundation" width="157" height="23"></a></div>
              <div class="col"> <a href="https://nationwideradiojm.com" target="_blank" class="logo-2" rel="noopener">
                  <img src="assets/img/footer/amber-media.png" alt="nnn" width="154" height="29"></a></div>
              <div class="col"> <a href="https://myambergroup.com/amber-launchpad.html" target="_blank" class="logo-2"
                  rel="noopener"> <img src="assets/img/footer/amber-launchpad.png"
                    alt="amber-launchpad" width="168" height="26"></a></div>
              <div class="col">
                <a href="https://amberontime.com/" target="_blank" class="logo-2"
                  rel="noopener"> <img src="assets/img/footer/amber-ontime.png"
                    alt="amber-ontime" width="152"  height="29"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 d-none">
          <div class="iso">
            <p>Kuya Techonologies Certified with</p>
            <div class="row mx-auto text-center">
              <div class="col-md-12 py-1">
                <img src="assets/img/footer/iso.png" alt="iso" class="my-2" width="154" height="86">
              </div>
              <div class="col-md-12 text-center my-4">
                <p>Amberpay Certified with</p>
                <img src="assets/img/footer/pci-logo-teal.png" alt="pci" width="132" height="40" class="mt-2">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-bottom" *ngIf="!isOpenCookie">
      <div class="container-xxl">
        <div class="row">
          <div class="cookies-btn" (click)="openCookie()">
            <img src="assets/img/footer/cookies-icon.svg" alt="cookies" width="36" height="36">
          </div>
          <div class="col-md-12 text-center py-2"> &copy; Amber IoT 2025 | All Rights Reserved.</div>
          <div class="col-md-4 text-end" *ngIf="showRocket" (click)="scrollTop()">
            <div class="showrocket"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="cookie-overlay d-block" *ngIf="isOpenCookie">
  <div class="bg-white p-3 pb-1">
    <h4 class="ml-4">Cookies Policy</h4>
    <div class="row mx-auto mt-1">
      <div class="col-md-8 my-auto">
        <div class="p-2">By clicking “Allow All”, you agree to the storing of cookies on your device to enhance site
          navigation, analyze site usage, and assist in our marketing efforts.
          You can learn more about how we use cookies by visiting our <a routerLink="/privacy-cookie"><strong>Cookie
              Policy </strong></a>and <a routerLink="/privacy-cookie"><strong>Privacy Policy.</strong></a></div>
      </div>
      <div class="col-md-4 my-auto text-center">
        <button class="btn btn-success mr-2" data-bs-toggle="modal" data-bs-target="#cookiesSettingsModal">Cookies
          Settings</button>
        <button class="btn btn-orange accept-cookies" (click)="accept()">Accept</button>
      </div>
    </div>
  </div>
</div>
 <!-- Modal -->
 <div class="modal fade cookiesSettingsModal" data-bs-backdrop="static" id="cookiesSettingsModal" tabindex="-1"
 aria-labelledby="cookiesSettingsModalLabel" aria-hidden="true">
 <div class="modal-dialog modal-lg" role="document">
   <div class="modal-content">
     <div class="modal-header">
       <img src="assets/img/footer/logo-colored-cookies.svg" class="w-100" alt=" " class="mx-2">
       <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
       <h5 class="modal-title d-block mb-2 mt-1" id="cookiesSettingsModalLabel">Policy Preferences</h5>
       <p>In common with many other websites, we use small files called cookies to help provide you with the best
         possible online experience. The purpose of this cookie policy is to inform you about what cookies are,
         how we use them, and what you can do to control them. This policy relates to this website only. </p>
       <h5 class="modal-title d-block mb-2 mt-2" id="cookiesSettingsModalLabel">Manage Consent Preferences</h5>
         <div id="accordion" class="accordion">
           <div class="accordion-item">
             <h2 class="accordion-header">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                 data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                 <a class="card-title">
                   <strong>Essential</strong>
                 </a>
               </button>
             </h2>
             <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordion">
               <div class="accordion-body">
                 These cookies are necessary for the operation of our website, or essential to the provision of
                 the services you have requested. These include cookies that allow you as a subscriber to our
                 services to stay logged in to your account and to access information, sections of our website,
                 or services not generally available to non-subscribers. If you block these cookies you will not
                 be able to access these services (which you may have paid for).
               </div>
             </div>
           </div>
           <div class="accordion-item">
             <h2 class="accordion-header">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                 data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                 <a class="card-title">
                   <strong>Performance</strong>
                 </a>
               </button>
             </h2>
             <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordion">
               <div class="accordion-body">
                 These cookies measure how often you visit our sites and how you use them. They also allow us to
                 calculate the number of visitors to our website, see what those visitors do on our website, how
                 long they spend on the website, and which links they click. These cookies help us to improve how
                 our website works, and enable us to make changes so that users stay longer or return more often.
                 If you block these cookies, we will not be able to improve our services as easily.
               </div>
             </div>
           </div>
           <div class="accordion-item">
             <h2 class="accordion-header">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                 data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                 <a class="card-title">
                   <strong>Targeting</strong>
                 </a>
               </button>
             </h2>
             <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordion">
               <div class="accordion-body">
                 Similar to performance cookies, these cookies tell us which pages you visit, and the links you
                 follow, so that we can make our website more relevant to your interests. If you block these
                 cookies, we will be unable to improve the experience our users get from our website.
               </div>
             </div>
           </div>

           <div class="accordion-item d-none">
             <h2 class="accordion-header">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                 data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                 <a class="card-title">
                   <strong>Functionality</strong>
                 </a>
               </button>
             </h2>
             <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordion">
               <div class="accordion-body">
                 These cookies make sure that you and your preferences are remembered when you return to our
                 site. That means that you can store data that you wish to return to, complete logs as to what
                 you have seen/used on the site, and generally allow us to provide you with a more personalized
                 experience. If you block these cookies you may have reduced functionality, or not be able to
                 store personal preferences.
               </div>
             </div>
           </div>

           <div class="accordion-item">
             <h2 class="accordion-header">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                 data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                 <a class="card-title">
                   <strong>Advertising</strong>
                 </a>
               </button>
             </h2>
             <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordion">
               <div class="accordion-body">
                 These collect information about your visit to our website, such as what you have looked at and
                 the links you have followed, so that we can target our advertising at you, or exclude certain
                 types of advertising that would not interest you. We can also gather information about your
                 browser, device and your IP address. If you block these cookies, you may receive advertising
                 that is not relevant to you.
               </div>
             </div>
           </div>

           <div class="accordion-item">
             <h2 class="accordion-header">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                 data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                 <a class="card-title">
                   <strong>Third party cookies</strong>
                 </a>
               </button>
             </h2>
             <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordion">
               <div class="accordion-body">
                 These are used where we use third-party applications on our website to customize the services
                 that we provide, or to provide an increased functionality. They may relate to the use of social
                 media, or the use of another complementary service. If you block these cookies you may not be
                 able to access the services that you want to use.
               </div>
             </div>
           </div>
         </div>
       <div class="modal-footer mr-3">
         <button type="button" class="btn btn-secondary close-cookies d-none"
           data-bs-dismiss="modal">Close</button>
         <button type="button" class="btn btn-orange  accept-cookies" data-bs-dismiss="modal">Accept All</button>
       </div>
     </div>
   </div>
 </div>
</div>
